import { cx } from '@/utils/strings'
type SpinnerProps = {
  size?: string
  color?: 'white' | 'primaryBlue'
  borderWidth?: string
}
function Spinner({ size = '2.5rem', color = 'primaryBlue', borderWidth = '4px' }: SpinnerProps) {
  const style = { borderColor: 'white', borderWidth, height: size, width: size }
  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <div
        className={cx(
          'spinner',

          'animate-spin rounded-full border-4 !border-b-transparent',
          color === 'white' && `border-white`,
          color === 'primaryBlue' && `border-primaryBlue`
        )}
        {...{ style }}
      />
    </div>
  )
}

export default Spinner
